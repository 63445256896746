.Legend h1 {
  font-size: 1.2rem;
  margin: 0;
}

.Legend {
  font-size: 0.8rem;
  position: absolute;
  padding: 5px;
  border-radius: 5px;
  top: 10px;
  left: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.Legend > *+* {
  margin-top: 5px;
}

.Legend li {
  width: 140px;
  text-align: center;
  list-style-type: none;
  font-size: 14px;
  cursor: pointer;
}
.Legend li:hover {
  text-decoration: underline;
}
